import AxiosClient from "../api/AxiosClient.ts";
import {User} from "../../domain/users/models/input/User.ts";
import {
    URL_USER_AUTH_SILENTLY,
    URL_USER,
    URL_USER_CONFIRM_AGGREGATION,
    URL_USER_ME
} from "../api/constants.ts";
import {UserAuth} from "../../domain/users/models/input/UserAuth.ts";
import {UsersConfirmAggregatePayload} from "../../domain/queries/UsersConfirmAggregatePayload.ts";
import {UserAggregateConfirmation} from "../../domain/users/models/input/UserAggregateConfirmation.ts";
import {UsersGetByIdPayload} from "../../domain/queries/UsersGetByIdPayload.ts";

export default class UsersRepository {
    public static get = async (): Promise<User> => {
        const result = await AxiosClient.get(URL_USER_ME());
        return result.data as User;
    }

    public static getById = async (payload: UsersGetByIdPayload): Promise<User> => {
        const result = await AxiosClient.get(URL_USER(payload.userId));
        return result.data as User;
    }

    public static authSilently = async (payload: object): Promise<UserAuth> => {
        const result = await AxiosClient.post(
            URL_USER_AUTH_SILENTLY(),
            payload
        );
        return result.data as UserAuth;
    }

    public static confirmAggregate = async (payload: UsersConfirmAggregatePayload): Promise<UserAggregateConfirmation> => {
        const result = await AxiosClient.get(
            URL_USER_CONFIRM_AGGREGATION(payload.userId)
        );
        return result.data as UserAggregateConfirmation;
    }
}
