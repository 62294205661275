import {createReducer} from "@reduxjs/toolkit";
import {updateKycbAction} from "../actions/kycbActions.ts";

export interface KycbReducerState {
    kycbUserId: string|null
    insightsDateFilter?: string|null
}

export const initState: KycbReducerState = {
    kycbUserId: null,
    insightsDateFilter: null
};

const kycbReducer = createReducer(initState, builder => {
    // @ts-expect-error unused state
    builder.addCase(updateKycbAction, (state, action) => {
        return action.payload;
    })
});

export default kycbReducer;
